import React, { useCallback, useEffect, useState } from "react";
import { getAtualDate } from "./utils";
import { PointsCards } from "./PointsCards";
import { DashboardContainer } from "./style";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardPage } from "../../components/DashboardPage";
import { ReservationsCard } from "./ReservationsCard";
import { RankingClients } from "./RankingClients";
import { useGlobal } from "../../hooks/global";
import { useApi } from "../../hooks/api";
import { correct_date_filter, setUpConsultation } from "../../services/generalServices";
import { io, Socket } from "socket.io-client";

const initalRange = {
  from: { day: 1, month: 1, year: 2024 },
  to: getAtualDate({ daysBefore: 0 }),
};

export const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const [range, setRange] = useState<{ from: any; to: any }>(initalRange);
  const [controlRender, setControlRender] = useState(false);
  const [statisticsPerStatus, setStatisticsPerStatus] = useState({});
  const [statisticsPerOrigin, setStatisticsPerOrigin] = useState({});
  const [reservationInfos, setReservationInfos] = useState({});
  const [pointsStatistics, setPointsStatistics] = useState({});
  const [exchangeRate, setExchangeRate] = useState(50);
  const [socket, setSocket] = useState<Socket>();

  const { theme, getCorrectMaxWidth, notify, loadingResize } = useGlobal();
  const { api } = useApi()

  const search = useCallback(async () => {
    setLoading(true)
    try {
      const date_init = range.from ? range.from : (range.to ? range.to : null)
      const date_end = range.to ? range.to : (range.from ? range.from : null)
      const start_date = date_init ? correct_date_filter(date_init) : null
      const end_date = date_end ? correct_date_filter(date_end) : null

      const query = setUpConsultation({ end_date, start_date })
      const [{ data: generalData }, { data: reservationsInfosData }, { data: byStatusData }, { data: byOriginData }, { data: constantsData }] = await Promise.all([
        await api.get('/general-points' + '?' + query),
        await api.get('/reservations-infos' + '?' + query),
        await api.get('/aggrouped-reservations' + '?' + query + '&aggruped_by=status'),
        await api.get('/aggrouped-reservations' + '?' + query + '&aggruped_by=origin'),
        await api.get('/constants')
      ])
      setPointsStatistics(generalData)
      setReservationInfos(reservationsInfosData)
      setStatisticsPerStatus(byStatusData)
      setStatisticsPerOrigin(byOriginData)
      setExchangeRate(constantsData.EXCHANGE_RATE)
    } catch (err) {
      notify('Erro ao buscar dados do dashboard', 'error')
    }
    setLoading(false)
  }, [range])

  useEffect(() => { search() }, [controlRender, range])

  useEffect(() => { setControlRender((prev) => !prev) }, [loadingResize])

  useEffect(() => {
    const socketInstance = io(`${process.env.REACT_APP_API}`);

    setSocket((atual) => {
      atual?.removeAllListeners();
      atual?.disconnect();
      return socketInstance;
    });

    if (socketInstance) {
      socketInstance?.on('wpp-events', async (data) => {
        if (data.status === 'connected') notify('Whatsapp conectado', 'success')
        else notify('Conexão com o Whatsapp perdida', 'alert')
        await search()
      });
    }
  }, []);

  const children = (
    <DashboardContainer>
      <DashboardHeader
        lastUpdate={lastUpdate}
        loading={loading}
        onSearch={() => setControlRender((prev) => !prev)}
        range={range}
        setRange={setRange}
      />
      <ReservationsCard
        loading={loading}
        statisticsPerStatus={statisticsPerStatus}
        statisticsPerOrigin={statisticsPerOrigin}
        reservationInfos={reservationInfos as any}
      />
      <PointsCards
        loading={loading}
        statistics={pointsStatistics as any}
        exchange_rate={exchangeRate}
      />
      <RankingClients
        theme={theme}
        range={range}
        maxWidth={getCorrectMaxWidth()}
        minWidth={250}
      />
    </DashboardContainer>
  )

  return (
    <DashboardPage>
      {controlRender && children}
      {!controlRender && children}
    </DashboardPage>
  )
};
