export const PERMISSIONS = {
  USERS: {
    'USERS-LIST': 'LISTAGEM DE USUÁRIOS',
    'USERS-CREATE': 'CADASTRO DE USUÁRIOS',
    'USERS-UPDATE': 'EDIÇÃO DE USUÁRIOS',
  },
  USER_TYPES: {
    'USER-TYPES-LIST': 'LISTAGEM DE TIPOS DE USUÁRIOS',
    'USER-TYPES-CREATE': 'CADASTRO DE TIPOS DE USUÁRIOS',
    'USER-TYPES-UPDATE': 'EDIÇÃO DE TIPOS DE USUÁRIOS',
    'USER-TYPES-DELETE': 'EXCLUSÃO DE TIPOS DE USUÁRIOS',
  },
  ACCOMMODATIONS: {
    'ACCOMMODATIONS-LIST': 'LISTAGEM DE ACOMODAÇÕES',
    'ACCOMMODATIONS-UPDATE': 'EDIÇÃO DE ACOMODAÇÕES',
  },
  ACCOMMODATIONS_GROUPS: {
    'ACCOMMODATIONS-GROUPS-LIST': 'LISTAGEM DE GRUPOS DE ACOMODAÇÕES',
    'ACCOMMODATIONS-GROUPS-CREATE': 'CADASTRO DE GRUPOS DE ACOMODAÇÕES',
    'ACCOMMODATIONS-GROUPS-UPDATE': 'EDIÇÃO DE GRUPOS DE ACOMODAÇÕES',
    'ACCOMMODATIONS-GROUPS-DELETE': 'EXCLUSÃO DE GRUPOS DE ACOMODAÇÕES',
  },
  'CHECK-INS': {
    'CHECK-INS-LIST': 'LISTAGEM DE CHECK-INS',
    'CHECK-INS-CREATE': 'CADASTRO DE CHECK-INS',
    'CHECK-INS-UPDATE': 'EDIÇÃO DE CHECK-INS',
    'CHECK-INS-DELETE': 'EXCLUSÃO DE CHECK-INS',
  },
  'STAYS-RESERVATIONS': {
    'STAYS-RESERVATIONS-LIST': 'LISTAGEM DE RESERVAS',
    'STAYS-RESERVATIONS-UPDATE': 'EDIÇÃO DE RESERVAS',
  },
  SMARTLOCKS: {
    'SMARTLOCKS-LIST': 'LISTAGEM DE SMARTLOCKS',
    'SMARTLOCKS-OPEN': 'ABERTURA DE SMARTLOCKS',
  },
  SETTINGS: {
    'SETTINGS-LIST': 'LISTAGEM DE CONFIGURAÇÕES',
    'SETTINGS-UPDATE': 'EDIÇÃO DE CONFIGURAÇÕES',
  },
  'MESSAGE-MODELS': {
    'MESSAGE-MODELS-LIST': 'LISTAGEM DE MODELOS DE MENSAGENS',
    'MESSAGE-MODELS-CREATE': 'CADASTRO DE MODELOS DE MENSAGENS',
    'MESSAGE-MODELS-UPDATE': 'EDIÇÃO DE MODELOS DE MENSAGENS',
    'MESSAGE-MODELS-DELETE': 'EXCLUSÃO DE MODELOS DE MENSAGENS',
  },
  GENERAL: {
    DASHBOARD: 'DASHBOARD',
    HISTORIES: 'HISTÓRICOS DE ACESSO',
    SEND_EMAILS: 'ENVIO DE E-MAILS AVULSOS',
    SEND_WPP: 'ENVIO DE MENSAGENS DE WPP AVULSAS',
    NOTIFICATIONS: 'CONFIGURAR REGRAS DE NOTIFICAÇÕES',
    INTEGRATIONS: 'CONFIGURAR INTEGRAÇÕES',
  },
};

export const allPermissionsOptions = Object.values(PERMISSIONS).reduce(
  (acc: any, curr: any) => {
    const keys = Object.keys(curr);
    const permissions = keys.map((value: any) => ({
      value,
      label: curr[value],
    }));
    return [...acc, permissions];
  },
  []
);

export const permissionsOptionsByType = (type: keyof typeof PERMISSIONS) => {
  const permissions = Object.entries(PERMISSIONS[type]).map(
    ([value, label]) => ({ value, label })
  );
  return permissions;
};

export const permissionsByType = (type: keyof typeof PERMISSIONS) => {
  const permissions = Object.entries(PERMISSIONS[type]).map(([value]) => value);
  return permissions;
};

export const allPermissionsValues = Object.values(PERMISSIONS).reduce(
  (acc: any, curr: any) => {
    const keys = Object.keys(curr);
    return [...acc, ...keys];
  },
  []
);

export const permissionsLabels: any = {
  USER_TYPES: "Tipos de Usuários",
  USERS: "Usuários",
  ACCOMMODATIONS: "Acomodações",
  "CHECK-INS": "Check-ins",
  "STAYS-RESERVATIONS": "Reservas",
  SMARTLOCKS: "Fechaduras",
  SETTINGS: "Configurações",
  GENERAL: "Geral",
  "MESSAGE-MODELS": "Modelos de Mensagens",
  ACCOMMODATIONS_GROUPS: "Grupos de Acomodações",
};

export type IPermissionsTypes =
  | keyof typeof PERMISSIONS.USERS
  | keyof typeof PERMISSIONS.USER_TYPES
  | keyof typeof PERMISSIONS.ACCOMMODATIONS
  | keyof (typeof PERMISSIONS)["STAYS-RESERVATIONS"]
  | keyof (typeof PERMISSIONS)["CHECK-INS"]
  | keyof (typeof PERMISSIONS)["SMARTLOCKS"]
  | keyof (typeof PERMISSIONS)["SETTINGS"]
  | keyof (typeof PERMISSIONS)["GENERAL"]
  | keyof (typeof PERMISSIONS)["MESSAGE-MODELS"]
  | keyof typeof PERMISSIONS.ACCOMMODATIONS_GROUPS;
